import { useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import { useAuth } from '../components/AuthProvider';
import Logo from '../components/Logo';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  alert: {
    marginTop: 10,
    marginBottom: 10,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const router = useRouter();
  const classes = useStyles();
  const { login, error, loading } = useAuth();

  async function handleLogin(event) {
    event.preventDefault();
    login(email, password, router.query.redirect);
  }

  return (
    <Container component="main" maxWidth="xs" className="content">
      <div className={classes.paper}>
        <Logo />
        <form className={classes.form} onSubmit={handleLogin}>
          {error && (
            <Alert severity="error" className={classes.alert}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            required
            fullWidth
            variant="outlined"
            margin="normal"
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            fullWidth
            variant="outlined"
            margin="normal"
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            Sign in
          </Button>
          <Box textAlign="center">
            <NextLink href="/account/forgot" passHref>
              <Link variant="body2" underline="none">
                Forgot password?
              </Link>
            </NextLink>
          </Box>
        </form>
      </div>
    </Container>
  );
}
